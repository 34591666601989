import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
    document.documentElement.style.setProperty("overflow", "hidden");
  }

  close() {
    document.documentElement.style.removeProperty("overflow");
    this.element.parentElement.removeAttribute("src");
    this.element.remove();
  }
}
