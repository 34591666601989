import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static targets = [
    "backgroundContainer",
    "formScreen",
    "form",
    "confirmation",
    "backButton",
    "actionButton",
    "screenIndicator",
    "submitButton",
    "resultsFrame",
    "confirmationBody",
  ];
  static values = {
    dynamicBackground: Boolean,
    backgroundColor: String,
    loopingBackground: Boolean,
    backgroundSpeed: Number,
    formScreen: Number,
    actionButtonType: String,
    actionButtonLabel: String,
    stepNumber: Number, // NOTE: could be NaN, so watch out
    backButtonVisible: Boolean,
  };
  static colors = [
    "#C4D956",
    "#45BC4A",
    "#9BC765",
    "#D9ECE0",
    "#C0EDE6",
    "#C6C7E5",
    "#FEE48C",
    "#F9CDC2",
    "#F2DFD9",
    "#FA7752",
    "#F55E6C",
    "#6A9EED",
    "#D8E3EF",
    "#FBF8AA",
    "#C2B56C",
    "#8D8969",
    "#7C93AC",
    "#F3EDD7",
  ];

  connect() {
    this.loopThroughBackgroundColors();
    window.submission = this;
  }

  loopThroughBackgroundColors() {
    clearTimeout(this.backgroundLoopTimeout);
    if (!this.dynamicBackgroundValue || !this.loopingBackgroundValue) {
      return;
    }

    this.backgroundSpeedValue = 0.1;
    const { colors } = this.constructor;
    const nextColor = colors[Math.round(Math.random() * (colors.length - 1))];

    if (this.backgroundColorValue == nextColor) {
      return this.loopThroughBackgroundColors();
    }

    this.backgroundColorValue = nextColor;

    this.backgroundLoopTimeout = setTimeout(() => {
      if (this.loopingBackgroundValue) {
        this.loopThroughBackgroundColors();
      }
    }, this.backgroundAnimationDuration);
  }

  startBackgroundLoop() {
    this.loopingBackgroundValue = true;
    this.backgroundSpeedValue = 0.1;
    this.loopThroughBackgroundColors();
  }

  stopBackgroundLoop() {
    this.loopingBackgroundValue = false;
    this.backgroundSpeedValue = 1;
  }

  changeBackgroundColor(e) {
    let color = e.detail.color || e.params.color;
    this.backgroundSpeedValue = 2;
    this.backgroundColorValue = color;
  }

  backgroundColorValueChanged(color) {
    if (!this.hasBackgroundContainerTarget || !this.dynamicBackgroundValue) {
      return;
    }

    const currentBackground = document.createElement("div");
    currentBackground.style.setProperty("background-color", color);
    currentBackground.style.setProperty("position", "absolute");
    currentBackground.style.setProperty("top", "0");
    currentBackground.style.setProperty("left", "0");
    currentBackground.style.setProperty("width", "100%");
    currentBackground.style.setProperty("height", "100%");
    currentBackground.dataset.color = color;
    currentBackground.style.setProperty("transform", "translateX(100%)");

    const keyframes = [
      { transform: "translateX(100%)" },
      { transform: "translate(0)" },
    ];
    const timing = {
      duration: this.backgroundAnimationDuration,
      iterations: 1,
      fill: "forwards",
    };

    this.backgroundContainerTarget.appendChild(currentBackground);
    const animation = currentBackground.animate(keyframes, timing);
    animation.finished.then(() => {
      const otherBackgrounds = this.backgroundContainerTarget.querySelectorAll(
        `div:not([data-color='${this.backgroundColorValue}'])`
      );

      if (otherBackgrounds.length > 0) {
        otherBackgrounds.forEach((background) => background.remove());
      }
    });
  }

  formScreenValueChanged(currentIndex, previousIndex) {
    if (!this.hasFormScreenTarget) {
      return;
    }

    const previousScreen =
      typeof previousIndex === "number" &&
      this.formScreenTargets[previousIndex];
    const currentScreen = this.formScreenTargets[currentIndex];
    const {
      submissionActionButtonType: actionButtonType,
      submissionActionButtonLabel: actionButtonLabel,
    } = currentScreen.dataset;

    if (previousIndex !== currentIndex && previousScreen) {
      previousScreen.style.setProperty("display", "none");
      previousScreen.setAttribute("aria-hidden", true);
      previousScreen.setAttribute("inert", true);
    }
    currentScreen.style.setProperty("display", "block");
    currentScreen.setAttribute("aria-hidden", false);
    currentScreen.removeAttribute("inert");
    currentScreen.focus();
    this.currentScreen = currentScreen;

    this.actionButtonTypeValue = actionButtonType;
    this.actionButtonLabelValue = actionButtonLabel;

    const { submissionStepNumber } = currentScreen.dataset;
    const isWithinRange =
      submissionStepNumber &&
      submissionStepNumber > 0 &&
      submissionStepNumber <= this.stepCount;
    if (isWithinRange) {
      this.stepNumberValue = submissionStepNumber;
    } else {
      this.stepNumberValue = null;
    }

    this.backButtonVisibleValue = currentIndex > 0;
  }

  updateActionButton(e) {
    const type = e.detail.type || e.params.actionButtonType;
    const label = e.detail.label || e.params.actionButtonLabel;
    this.actionButtonTypeValue = type;
    this.actionButtonLabelValue = label;
  }

  updateBackButton(e) {
    const visible = (e.detail.visible || e.params.backButtonVisible) === "true";
    this.backButtonVisibleValue = visible;
  }

  next() {
    if (this.formScreenValue === this.formScreenTargets.length - 1) {
      return;
    }

    this.formScreenValue += 1;
    this.loopingBackgroundValue = false;
  }

  previous() {
    if (this.formScreenValue === 0) {
      return;
    }

    this.formScreenValue -= 1;
    this.loopingBackgroundValue = this.formScreenValue === 0;
  }

  reset() {
    window.location.reload();
  }

  // TODO: error handling
  submit() {
    // Empty inputs will show up in the resultant Submission record, so we want to get rid of them.
    // This is a hacky way to do it, but it works.
    // It does however destroy the page, so the page needs to be reloaded after submission.
    const inputs = this.formTarget.querySelectorAll("input, textarea");
    inputs.forEach((input) => {
      if (Boolean(input.value)) {
        return;
      }

      input.remove();
    });
    const formData = new FormData(this.formTarget);

    fetch(this.formTarget.action, {
      method: "POST",
      body: formData,
    }).then((response) => {
      this.updateConfirmation({ status: response.ok ? "success" : "error" });
      if (response.ok) {
        this.next();
      }
    });
  }

  updateConfirmation({ status }) {
    if (!this.hasConfirmationTarget) {
      return;
    }

    this.confirmationTarget.dataset.status = status;
  }

  reset() {
    window.location.reload();
  }

  actionButtonTypeValueChanged(actionType) {
    if (!this.hasActionButtonTarget) {
      return;
    }

    const defaultAction = "submission#changeBackgroundColor";

    switch (actionType) {
      case "submit":
        this.actionButtonTarget.dataset.action = `click->submission#submit ${defaultAction}`;
        this.actionButtonTarget.dataset.submissionColorParam = "#ffffff";
        break;
      case "next":
        this.actionButtonTarget.dataset.action = `click->submission#next ${defaultAction}`;
        this.actionButtonTarget.dataset.submissionColorParam = "#ffffff";
        break;
      case "reset":
        this.actionButtonTarget.dataset.action = `click->submission#reset ${defaultAction}`;
        this.actionButtonTarget.dataset.submissionColorParam = "#ffffff";
        break;
      case "closeResults":
        this.actionButtonTarget.dataset.action = `click->submission#closeResults ${defaultAction}`;
        this.actionButtonTarget.dataset.submissionColorParam = "#ffffff";
        break;
      case "":
      case null:
        this.actionButtonTarget.dataset.action = `${defaultAction}`;
        this.actionButtonTarget.dataset.submissionColorParam = "#ffffff";
    }
  }

  actionButtonLabelValueChanged(label) {
    if (!this.hasActionButtonTarget) {
      return;
    }

    if (label) {
      this.actionButtonTarget.innerText = label;
      this.actionButtonTarget.style.setProperty("display", "block");
      this.actionButtonTarget.animate([{ opacity: 0 }, { opacity: 1 }], {
        duration: 200,
        fill: "forwards",
      });
    } else {
      this.actionButtonTarget
        .animate([{ opacity: 1 }, { opacity: 0 }], {
          duration: 200,
          fill: "forwards",
        })
        .finished.then(() => {
          this.actionButtonTarget.style.setProperty("display", "none");
        });
    }
  }

  get stepCount() {
    return parseInt(this.element.dataset.stepCount, 10);
  }

  stepNumberValueChanged(stepNumber, previousValue) {
    if (!this.hasScreenIndicatorTarget) {
      return;
    }

    const currentlyIsValid = typeof stepNumber == "number" && stepNumber > 0;
    const previouslyWasValid =
      typeof previousValue == "number" && previousValue > 0;

    if (currentlyIsValid) {
      this.screenIndicatorTarget.innerText = `${stepNumber} / ${this.stepCount}`;
      this.screenIndicatorTarget.setAttribute(
        "aria-label",
        `Step ${stepNumber} of ${this.stepCount}`
      );
    }

    if (currentlyIsValid && !previouslyWasValid) {
      this.screenIndicatorTarget.style.setProperty("display", "block");
      this.screenIndicatorTarget.animate([{ opacity: 0 }, { opacity: 1 }], {
        duration: 200,
        fill: "forwards",
      });
    } else if (previouslyWasValid && !currentlyIsValid) {
      this.screenIndicatorTarget
        .animate([{ opacity: 1 }, { opacity: 0 }], {
          duration: 200,
          fill: "forwards",
        })
        .finished.then(() => {
          this.screenIndicatorTarget.style.setProperty("display", "none");
        });
    }
  }

  get backgroundAnimationDuration() {
    return window.innerWidth / this.backgroundSpeedValue;
  }

  backButtonVisibleValueChanged(visible) {
    if (!this.hasBackButtonTarget) {
      return;
    }

    if (visible) {
      this.backButtonTarget.style.setProperty("display", "block");
      this.backButtonTarget.animate([{ opacity: 1 }], {
        duration: 200,
        fill: "forwards",
      });
    } else {
      this.backButtonTarget
        .animate([{ opacity: 0 }], {
          duration: 200,
          fill: "forwards",
        })
        .finished.then(() => {
          this.backButtonTarget.style.setProperty("display", "none");
        });
    }
  }

  closeResults() {
    if (!this.hasResultsFrameTarget || !this.hasConfirmationTarget) {
      return;
    }

    this.resultsFrameTarget.src = "";
    this.resultsFrameTarget.innerHTML = "";

    this.updateActionButton({
      detail: {
        type: "reset",
        label: "Restart",
      },
    });
    this.updateBackButton({
      detail: {
        visible: "true",
      },
    });

    this.confirmationBodyTarget.removeAttribute("inert");
    this.confirmationBodyTarget.focus();
  }

  openResults(e) {
    if (!this.hasResultsFrameTarget || !this.hasConfirmationTarget) {
      return;
    }

    const { url } = e.params;

    this.resultsFrameTarget.addEventListener("turbo:frame-load", () => {
      const firstChild = this.resultsFrameTarget.firstElementChild;
      if (!firstChild) {
        return;
      }

      this.confirmationBodyTarget.setAttribute("inert", "true");
      firstChild.setAttribute("tabindex", "0");
      firstChild.focus();
    });

    Turbo.visit(url, {
      action: "replace",
      frame: "results",
    });
  }
}
