import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "optionList",
    "optionListItem",
    "selectionList",
    "selectionListItem",
    "selectionTemplate",
    "customSelectionTemplate",
    "selectionLabel",
    "nextButtonContainer",
    "nextButton",
    "formOption",
  ];
  static values = {
    count: Number,
    complete: Boolean,
    inputNamePrefixes: Array,
  };

  connect() {
    window.reflection = this;
  }

  deselect(e) {
    const { selection } = e.params;
    const selectionStr = selection.toString();

    const optionListItem = this.optionListItemTargets.find(
      (item) => item.dataset.reflectionSelectionParam === selectionStr
    );
    optionListItem.disabled = false;
    e.target.closest("li").remove();
    this.countValue--;
    this.resetToLastColor();
  }

  resetToLastColor() {
    const lastSelection =
      this.selectionListItemTargets[this.selectionListItemTargets.length - 1];
    if (lastSelection) {
      const { color } = lastSelection.dataset;
      this.dispatch("color-change", { detail: { color } });
    } else {
      this.dispatch("color-change", { detail: { color: "#FFFFFF" } });
    }
  }

  select(e) {
    const { selection, color } = e.params;
    const selectionStr = selection.toString();

    const timeStamp = new Date().getTime();

    const hiddenInputs = document.createRange().createContextualFragment(`
      <div class="visually-hidden">
        <input type="hidden" name="submission[submission_reflections_attributes][${timeStamp}][reflection_id]" value="${selection}">
        <input type="hidden" name="submission[submission_reflections_attributes][${timeStamp}][magic_module_instance_id]" value="${this.data.get(
      "magic-module-instance-id"
    )}">
        <input type="hidden" name="submission[submission_reflections_attributes][${timeStamp}][position]" value="${
      this.countValue
    }">
      </div>
    `);

    e.target.disabled = true;

    const selectionDisplay =
      this.selectionTemplateTarget.content.cloneNode(true);
    const selectionElement = selectionDisplay.querySelector("li");
    selectionElement.dataset.color = color;
    selectionElement.dataset.reflectionTarget = "selectionListItem";
    const label = selectionDisplay.querySelector(
      "[data-reflection-target='selectionLabel']"
    );

    selectionElement.appendChild(hiddenInputs);

    const removeButton = selectionDisplay.querySelector("button");
    label.textContent = e.target.textContent;
    removeButton.dataset.action = "reflection#deselect";
    removeButton.dataset.reflectionSelectionParam = selectionStr;

    this.selectionListTarget.appendChild(selectionDisplay);

    this.countValue++;
    this.dispatch("color-change", { detail: { color } });
  }

  addCustomSelection() {
    const timeStamp = new Date().getTime();
    const hiddenInputs = document.createRange().createContextualFragment(`
      <div class="visually-hidden">
        <input type="hidden" name="submission[submission_reflections_attributes][${timeStamp}][magic_module_instance_id]" value="${this.data.get(
      "magic-module-instance-id"
    )}">
        <input type="hidden" name="submission[submission_reflections_attributes][${timeStamp}][position]" value="${
      this.countValue
    }">
      </div>
    `);

    const prompt = this.customSelectionTemplateTarget.content.cloneNode(true);
    const promptElement = prompt.querySelector("li");
    // TODO: pick random color
    promptElement.dataset.color = "#cccccc";
    promptElement.dataset.reflectionTarget = "selectionListItem";
    const removeButton = prompt.querySelector("button");
    removeButton.dataset.action = "reflection#removeCustomSelection";
    const input = prompt.querySelector("input");
    input.setAttribute(
      "name",
      `submission[submission_reflections_attributes][${timeStamp}][custom]`
    );
    prompt.appendChild(hiddenInputs);
    this.selectionListTarget.appendChild(prompt);
    input.focus();
    this.countValue++;
    this.dispatch("color-change", { detail: { color: "#cccccc" } });
  }

  removeCustomSelection(e) {
    e.target.closest("li").remove();
    this.countValue--;
    this.optionListItemTarget.focus();
    this.resetToLastColor();
  }

  countValueChanged(count) {
    this.completeValue = count === 3;
  }

  completeValueChanged(complete) {
    const elementToFadeOut = complete
      ? this.optionListTarget
      : this.nextButtonContainerTarget;
    const elementToFadeIn = complete
      ? this.nextButtonContainerTarget
      : this.optionListTarget;
    const elementToFocus = complete
      ? this.nextButtonTarget
      : this.optionListTarget;
    const fadeOut = elementToFadeOut.animate([{ opacity: 1 }, { opacity: 0 }], {
      duration: 300,
      fill: "forwards",
    });
    fadeOut.finished
      .then(() => {
        elementToFadeOut.style.setProperty("display", "none");
        elementToFadeIn.style.setProperty("display", "block");
        elementToFadeIn.style.setProperty("opacity", "0");
        const fadeIn = elementToFadeIn.animate(
          [{ opacity: 0 }, { opacity: 1 }],
          {
            duration: 300,
            fill: "forwards",
          }
        );
        return fadeIn.finished;
      })
      .then(() => {
        elementToFocus.focus();
        console.log("focus the next button", elementToFocus);
      });
  }

  returnFocusToOptions() {
    this.optionListItemTarget.focus();
  }
}
