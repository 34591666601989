import { Controller } from "@hotwired/stimulus";
import qs from "qs";

const ONE_MINUTE = 1000 * 60;
const FIVE_MINUTES = ONE_MINUTE * 5;
const ONE_DAY = 1000 * 60 * 60 * 24;
const TWO_DAYS = ONE_DAY * 2;
const SIX_HOURS = 1000 * 60 * 60 * 6;
const THIRTY_MINUTES = 1000 * 60 * 30;
// const SCALE = 1 / THIRTY_MINUTES; // 1px = 30 minutes
// const SCALE = 1 / ONE_MINUTE; // 1px = 1 minute
// const SCALE = 1 / ONE_DAY; // 1px = 1 day

const lastArrayItem = (array) => array[array.length - 1];
const MAX_CONCURRENT_REQUESTS = 10;
const DATE_LABEL_MARGIN = 32;
const DATE_LABEL_CAP_HEIGHT = 0.75;
const DATE_LABEL_DESCENDER_HEIGHT = 0.05;
const DATE_LABEL_DISTANCE_FROM_LINE = 10;
const DATE_LABEL_FONT_SIZE = 20;
const DATE_LABEL_CONDENSED_FONT_FAMILY = "HEX Franklin Condensed, sans-serif";
const DATE_LABEL_EXTRA_CONDENSED_FONT_FAMILY =
  "HEX Franklin Extra Condensed, sans-serif";
const DATE_LABEL_FONT_WEIGHT = "800";
const ANIMATION_SPEED = 0.2;
const NOTIFICATION_OVERLAP = 0.375;
const FADE_DURATION = 1000;

const wait = (duration) => {
  const ctx = this;
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve.call(ctx);
    }, duration);
  });
};

export default class extends Controller {
  static targets = [
    "mainContent",
    "title",
    "currentViewLabel",
    "graphic",
    "svg",
    "legend",
    "legendItemTemplate",
    "notificationTemplate",
    "noDataMessage",
  ];
  static values = {
    oldestSnapshot: Number, // Date stored as unix timestamp
    newestPoll: Number, // Date stored as unix timestamp
    snapshots: Array,
    currentView: String,
  };

  connect() {
    window.leaderboard = this;

    this.version = this.data.get("version");
    this.checkForNewerVersion();

    this.oldestSnapshotValue = new Date().getTime();
    this.newestPollValue = new Date().getTime();
    this.createdAt = parseInt(this.data.get("created-at"));
    this.speed = parseFloat(this.data.get("speed"));
    this.maxHistoricalDate = parseInt(this.data.get("max-historical-date"));
    this.historicalTimeframeLabel = this.data.get("historical-timeframe-label");
    this.timeToSpendOnRealtimeData =
      parseInt(this.data.get("time-to-spend-on-realtime-data")) * 1000;
    this.timeToSpendOnTop5Ranking =
      parseInt(this.data.get("time-to-spend-on-top-5-ranking")) * 1000;
    this.showHistoricalNotifications =
      this.data.get("show-historical-notifications") === "true";
    this.scale = parseInt(this.data.get("time-scale")) / ONE_DAY;
    this.debugMode = this.data.get("debug-mode") === "true";
    this.historicalSnapshotInterval =
      parseInt(this.data.get("historical-snapshot-interval")) * 60 * 1000;

    switch (this.data.get("timing-function")) {
      case "quadratic":
        this.timingFunction = (t) => t * t;
        this.timingFunctionStr = "cubic-bezier(.55,.09,.68,.53)";
      case "cubic":
        this.timingFunction = (t) => t * t * t;
        this.timingFunctionStr = "cubic-bezier(.55,.06,.68,.19)";
        break;
      case "quartic":
        this.timingFunction = (t) => t * t * t * t;
        this.timingFunctionStr = "cubic-bezier(.77,0,.18,1)";
        break;
      case "quintic":
        this.timingFunction = (t) => t * t * t * t * t;
        this.timingFunctionStr = "cubic-bezier(.86,0,.07,1)";
        break;
      default:
        this.timingFunction = (t) => t;
        this.timingFunctionStr = "linear";
    }

    this.legendItemTemplate = this.legendItemTemplateTarget.cloneNode(true);
    this.history = [];
    this.paths = {};
    this.dateLabels = [];
    this.ribbons = {};
    this.legendItems = {};
    this.notifications = [];

    // Request data since the beginning of time
    this.requestHistoricalData()
      .then(() => {
        // If we don't have any history to show, then just return early
        if (!Boolean(this.history.length)) {
          return Promise.reject();
        }

        return this.fadeInEverything();
      })
      .then(() => {
        return this.animateThroughHistory();
      })
      .then(() => {
        return this.trackRealtimeData();
      })
      .then(() => {
        return wait(this.timeToSpendOnRealtimeData);
      })
      .then(() => {
        return this.teardownGraphic();
      })
      .then(() => {
        return this.showTopFive();
      })
      .then(() => {
        return wait(this.timeToSpendOnTop5Ranking);
      })
      .then(() => {
        return this.fadeOutEverything();
      })
      .then(() => {
        return this.redirectToNextScreen();
      })
      .catch((e) => {
        return this.showErrorMessage()
          .then(() => {
            return wait(ONE_MINUTE);
          })
          .then(() => {
            return this.redirectToNextScreen;
          });
      });
  }

  checkForNewerVersion() {
    const check = () => {
      return fetch(this.data.get("version-url"))
        .then((res) => res.text())
        .then((res) => {
          if (res !== this.version) {
            window.location.reload();
          }
        });
    };

    this.versionCheckInterval = setInterval(check, 15000);
  }

  requestHistoricalData() {
    const dates = [];
    let date = new Date().getTime();
    let i = 0;
    let requestPool = [];
    while (date > this.maxHistoricalDate) {
      if (i < 10) {
        requestPool.push(date);
        i++;
      } else {
        dates.push(requestPool);
        requestPool = [];
        requestPool.push(date);
        i = 1;
      }
      date -= this.historicalSnapshotInterval;
    }
    dates.push(requestPool);

    const requests = dates.map((pool) => {
      return this.requestGroupOfData(pool);
    });

    return Promise.all(requests).then((responses) => {
      responses.forEach((response) => {
        Object.entries(response).forEach(([date, snapshotData]) => {
          this.updateHistory(parseInt(date), JSON.parse(snapshotData));
        });
      });

      this.updatePaths();
      this.drawSvg();
      this.constructLegendItems();
      this.oldestSnapshotValue = this.history[0]?.date;
    });
  }

  requestGroupOfData(dates) {
    const url = `${this.data.get("rankings-url")}?${qs.stringify({ dates })}`;
    return fetch(url, { headers: { accept: "application/json" } }).then(
      (response) => response.json()
    );
  }

  requestNextDate() {
    if (this.nextDate) {
      this.requestRankingsData(this.nextDate).then(({ date, data }) => {
        this.updateHistory(date, data);
        this.updatePaths();
        this.drawSvg();
        this.constructLegendItems();
      });
      this.oldestSnapshotValue = this.nextDate;
    } else {
      console.log("no more data to request");
    }
  }

  get nextDate() {
    const nextInterval =
      this.oldestSnapshotValue - this.historicalSnapshotInterval;
    if (nextInterval < this.createdAt) {
      return false;
    } else {
      return nextInterval;
    }
    // // For the first 24 hours, we'll request data every 30 minutes
    // // For the next 24 hours, we'll request data every 6 hours
    // // After that, we'll request data every 24 hours
    // const now = new Date().getTime();
    // let nextInterval;
    // if (now - this.oldestSnapshotValue < ONE_DAY) {
    //   nextInterval = this.oldestSnapshotValue - THIRTY_MINUTES;
    // } else if (now - this.oldestSnapshotValue < TWO_DAYS) {
    //   nextInterval = this.oldestSnapshotValue - SIX_HOURS;
    // } else {
    //   nextInterval = this.oldestSnapshotValue - ONE_DAY;
    // }

    // if (nextInterval < this.createdAt) {
    //   return false;
    // } else {
    //   return nextInterval;
    // }
  }

  requestRankingsData(unixTimestamp) {
    const url = `${this.data.get("rankings-url")}?date=${unixTimestamp || ""}`;
    return fetch(url, { headers: { accept: "application/json" } })
      .then((response) => response.json())
      .then((data) => {
        this.snapshotsValue = [
          {
            date: unixTimestamp,
            data,
          },
          ...this.snapshotsValue,
        ];
      });
  }

  requestRecentVotes() {
    // Only one poll request at a time
    if (this.pollRequest) {
      return;
    }

    const unixTimestamp = this.newestPollValue;
    const now = new Date().getTime();
    const url = `${this.data.get("poll-url")}?date=${unixTimestamp}`;

    this.pollRequest = fetch(url, { headers: { accept: "application/json" } })
      .then((response) => response.json())
      .then((data) => {
        if (Boolean(data.length)) {
          data.forEach(({ name, vote_count, color_hex }) => {
            this.notify(name, vote_count, color_hex);
          });
        }
        this.newestPollValue = now;
        this.pollRequest = null;
      });

    return this.pollRequest;
  }

  updateHistory(date, data) {
    if (!Boolean(data.length)) {
      return;
    }

    // Add new snapshot to history
    const snapshot = {};
    snapshot.date = date;
    snapshot.dateFormatted = new Date(date).toLocaleString("en-US", {
      month: "long",
      day: "numeric",
    });
    snapshot.rankings = data.map((ribbon) => ribbon.name);
    snapshot.ribbons = data.reduce((ribbonsAcc, ribbon, rank) => {
      ribbonsAcc[ribbon.name] = {
        ...ribbon,
        rank,
        rankChange: 0,
        previousRank: undefined,
      };
      return ribbonsAcc;
    }, {});
    snapshot.isDifferentCalendarDay = false;
    snapshot.previousDateFormatted = undefined;
    snapshot.dateChange = 0;
    snapshot.maxRankChange = 0;
    snapshot.ribbonsNoLongerInTop5 = [];
    this.history = [snapshot, ...this.history];

    // Keep a record of each ribbon's rank at this point in time
    data.forEach((ribbon, rank) => {
      this.ribbons[ribbon.name] ||= { ranks: [] };
      this.ribbons[ribbon.name].ranks = [
        { date, rank },
        ...this.ribbons[ribbon.name].ranks,
      ];
    });

    // Process the changes between new snapshot and previous snapshot
    const nextSnapshot = this.history[1];
    if (!nextSnapshot) {
      return;
    }
    nextSnapshot.dateChange = nextSnapshot.date - snapshot.date;
    nextSnapshot.isDifferentCalendarDay =
      nextSnapshot.dateFormatted !== snapshot.dateFormatted;
    nextSnapshot.previousDateFormatted = snapshot.dateFormatted;
    Object.entries(nextSnapshot.ribbons).forEach(([ribbonName, ribbon]) => {
      const previousribbon = snapshot.ribbons[ribbonName];
      ribbon.previousRank = previousribbon ? previousribbon.rank : 5;
      ribbon.previousVoteCount = previousribbon ? previousribbon.vote_count : 0;
      ribbon.voteCountChange = ribbon.vote_count - ribbon.previousVoteCount;
      if (previousribbon) {
        ribbon.rankChange = Math.abs(previousribbon.rank - ribbon.rank);
      } else {
        ribbon.rankChange = 5 - ribbon.rank;
      }
    });
    nextSnapshot.maxRankChange = Math.max(
      ...Object.values(nextSnapshot.ribbons).map((ribbon) => ribbon.rankChange)
    );
    nextSnapshot.ribbonsNoLongerInTop5 = snapshot.rankings
      .filter((ribbonName) => !nextSnapshot.ribbons[ribbonName])
      .map((ribbonName) => {
        return {
          name: ribbonName,
          previousRank: snapshot.ribbons[ribbonName].rank,
        };
      });
  }

  updatePaths() {
    // Update the paths
    const viewboxWidth = window.innerWidth;
    const strokeWidth = viewboxWidth / 5;
    const snapshotBuffer = strokeWidth;
    const rankStepLength = strokeWidth;
    const bufferForAngles = strokeWidth * 0.21;
    const getX = (rank) =>
      Math.round(rank * (viewboxWidth / 5) + strokeWidth / 2);

    let vizInfo = {};
    if (this.history.length === 1) {
      const snapshot = this.history[0];
      vizInfo.latestPositionY = window.innerHeight;
      vizInfo.dateLabels = [];
      vizInfo.paths = {};
      Object.values(snapshot.ribbons).forEach((ribbon) => {
        const x = getX(ribbon.rank);
        vizInfo.paths[ribbon.name] = {
          points: [
            { x, y: 0 },
            { x, y: window.innerHeight },
          ],
          color_hex: ribbon.color_hex,
        };
      });
      vizInfo.snapshotRanges = [
        {
          top: 0,
          height: window.innerHeight,
          snapshotIndex: 0,
        },
      ];
    } else {
      vizInfo = this.history.reduce(
        (vizInfo, snapshot, snapshotIndex) => {
          const {
            date,
            dateFormatted,
            isDifferentCalendarDay,
            previousDateFormatted,
            dateChange,
            ribbons,
            ribbonsNoLongerInTop5,
            maxRankChange,
          } = snapshot;
          const yStart = dateChange * this.scale + vizInfo.latestPositionY;
          const yStepsStart = yStart + snapshotBuffer;
          const yEnd = yStepsStart + maxRankChange * rankStepLength;
          vizInfo.latestPositionY = yEnd;

          // Record the y positions of this snapshot so that they can be referenced later
          // The active area of the snapshot is the area between the step end and the next snapshot's step start.
          vizInfo.snapshotRanges.push({
            date,
            snapshotIndex,
            yStart,
            yStepsStart,
            yEnd,
          });

          // Get x/y coordinates for each ribbon at this snapshot.
          // If the ribbon changes rank by more than 1 place, we'll need to add intermediate points.
          // If the ribbon was not in the top 5 at the previous snapshot, we'll need to add intermediate points as if it was previously ranked 6th (i.e. just off-screen)
          Object.values(ribbons).forEach((ribbon) => {
            const { name, color_hex, rank, previousRank, rankChange } = ribbon;
            if (!vizInfo.paths[name]) {
              vizInfo.paths[name] = {};
              vizInfo.paths[name].color_hex = color_hex;
              vizInfo.paths[name].points = [];
            }

            if (snapshotIndex === 0) {
              vizInfo.paths[name].points.push({
                x: getX(rank),
                y: yStart,
              });
            } else if (rankChange === 0) {
              vizInfo.paths[name].points.push({
                x: getX(rank),
                y: yEnd,
              });
            } else if (previousRank < rank) {
              for (let i = previousRank; i <= rank; i++) {
                vizInfo.paths[name].points.push({
                  x: getX(i),
                  y: yStepsStart + (i - previousRank) * rankStepLength,
                });
              }
              vizInfo.paths[name].points.push({
                x: getX(rank),
                y: yEnd,
              });
            } else if (previousRank > rank) {
              for (let i = previousRank; i >= rank; i--) {
                vizInfo.paths[name].points.push({
                  x: getX(i),
                  y: yStepsStart + (previousRank - i) * rankStepLength,
                });
              }
              vizInfo.paths[name].points.push({
                x: getX(rank),
                y: yEnd,
              });
            } else if (previousRank === undefined) {
            }
          });

          // Any ribbons that were previously in the top 5 but are no longer need to be drawn moving towards the 6th rank (i.e. just off-screen)
          ribbonsNoLongerInTop5.forEach(({ previousRank, name }) => {
            for (let i = parseInt(previousRank); i < 6; i++) {
              vizInfo.paths[name].points.push({
                x: getX(i),
                y: yStepsStart + (i - previousRank) * rankStepLength,
              });
            }
            vizInfo.paths[name].points.push({
              x: getX(5),
              y: yEnd,
            });
          });

          if (isDifferentCalendarDay) {
            vizInfo.dateLabels.push({
              todayLabel: dateFormatted,
              yesterdayLabel: previousDateFormatted,
              lineX1: DATE_LABEL_MARGIN,
              lineX2: viewboxWidth - DATE_LABEL_MARGIN,
              lineY: yStart,
              todayPosition:
                yStart +
                DATE_LABEL_CAP_HEIGHT * DATE_LABEL_FONT_SIZE +
                DATE_LABEL_DISTANCE_FROM_LINE,
              yesterdayPosition:
                yStart -
                DATE_LABEL_DISTANCE_FROM_LINE -
                DATE_LABEL_DESCENDER_HEIGHT * DATE_LABEL_FONT_SIZE,
            });
          }

          return vizInfo;
        },
        {
          paths: {},
          snapshotRanges: [],
          dateLabels: [],
          latestPositionY: 0,
        }
      );

      vizInfo.snapshotRanges = vizInfo.snapshotRanges.map((range, index) => {
        const nextRange = vizInfo.snapshotRanges[index + 1];
        if (nextRange) {
          range.top = range.yStart;
          range.height = nextRange.yStart - range.yStart;
          range.bottom = range.top + range.height;
        } else {
          range.top = range.yStart;
          range.height = vizInfo.latestPositionY - range.yStart;
          range.bottom = range.top + range.height;
        }
        return range;
      });
    }

    this.viewboxWidth = viewboxWidth;
    this.viewboxHeight = vizInfo.latestPositionY;
    this.strokeWidth = strokeWidth;
    this.paths = vizInfo.paths;
    this.dateLabels = vizInfo.dateLabels;
    this.snapshotRanges = vizInfo.snapshotRanges;
  }

  drawSvg() {
    this.svgTarget.innerHTML = "";
    this.svgTarget.setAttribute(
      "viewBox",
      `0 0 ${this.viewboxWidth} ${this.viewboxHeight}`
    );
    this.svgTarget.setAttribute("height", this.viewboxHeight);

    // Draw color-coded lines for each ribbon represented in the history,
    Object.entries(this.paths).forEach(
      ([ribbonName, { color_hex, points }]) => {
        const path = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "path"
        );
        path.setAttribute("id", ribbonName.replace(/\s/g, "-").toLowerCase());
        path.setAttribute("fill", "none");
        path.setAttribute("stroke", color_hex);
        path.style.setProperty("stroke", color_hex);
        path.setAttribute("stroke-width", this.strokeWidth);
        path.setAttribute("stroke-linecap", "square");
        path.setAttribute("stroke-linejoin", "miter");
        const d = points
          .map(({ x, y }, index) => {
            if (index > 0) {
              return `L${x},${y}`;
            } else {
              return `M${x},${y}`;
            }
          })
          .join(" ");
        path.setAttribute("d", d);
        this.svgTarget.appendChild(path);
        this.paths[ribbonName].path = path;
        this.paths[ribbonName].totalLength = path.getTotalLength();
      }
    );

    // Draw date labels
    this.dateLabels.forEach(
      (
        {
          todayLabel,
          todayPosition,
          yesterdayLabel,
          yesterdayPosition,
          lineX1,
          lineX2,
          lineY,
        },
        i
      ) => {
        const line = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "line"
        );
        line.setAttribute("x1", lineX1);
        line.setAttribute("x2", lineX2);
        line.setAttribute("y1", lineY);
        line.setAttribute("y2", lineY);
        line.setAttribute("stroke", "#000");
        line.setAttribute("stroke-width", 3);
        this.svgTarget.appendChild(line);

        const today = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "text"
        );
        today.setAttribute("x", DATE_LABEL_MARGIN);
        today.setAttribute("y", todayPosition);
        today.setAttribute("fill", "#000");
        today.setAttribute("font-size", DATE_LABEL_FONT_SIZE);
        today.setAttribute("font-family", DATE_LABEL_CONDENSED_FONT_FAMILY);
        today.setAttribute("font-weight", DATE_LABEL_FONT_WEIGHT);
        today.setAttribute("text-anchor", "start");
        today.textContent = todayLabel;
        this.svgTarget.appendChild(today);

        const yesterday = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "text"
        );
        yesterday.setAttribute("x", DATE_LABEL_MARGIN);
        yesterday.setAttribute("y", yesterdayPosition);
        yesterday.setAttribute("fill", "#000");
        yesterday.setAttribute("font-size", DATE_LABEL_FONT_SIZE);
        yesterday.setAttribute("font-family", DATE_LABEL_CONDENSED_FONT_FAMILY);
        yesterday.setAttribute("font-weight", DATE_LABEL_FONT_WEIGHT);
        yesterday.setAttribute("text-anchor", "start");
        yesterday.textContent = yesterdayLabel;

        this.svgTarget.appendChild(yesterday);

        const upArrow = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "use"
        );
        upArrow.setAttribute("href", "#up-arrow");
        upArrow.setAttribute("x", lineX2 - 16);
        upArrow.setAttribute("y", yesterdayPosition - 23);
        upArrow.setAttribute("width", "16");
        upArrow.setAttribute("height", "25");
        this.svgTarget.appendChild(upArrow);

        const downArrow = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "use"
        );
        downArrow.setAttribute("href", "#down-arrow");
        downArrow.setAttribute("x", lineX2 - 16);
        downArrow.setAttribute("y", todayPosition - 18);
        downArrow.setAttribute("width", "16");
        downArrow.setAttribute("height", "25");
        this.svgTarget.appendChild(downArrow);

        this.dateLabels[i].elements = [
          line,
          today,
          yesterday,
          upArrow,
          downArrow,
        ];
      }
    );

    // Draw the snapshot ranges
    this.snapshotRanges.forEach(({ top, height, snapshotIndex }, index) => {
      const div = document.createElement("div");

      div.style.setProperty("position", "absolute");
      div.style.setProperty("top", `${top}px`);
      div.style.setProperty("left", "0");
      div.style.setProperty("width", "80px");
      div.style.setProperty("height", `${height}px`);
      if (this.debugMode) {
        div.style.setProperty("background", "red");
        div.style.setProperty("opacity", "0.5");
        div.style.setProperty("outline", "1px solid black");
      }
      div.setAttribute("data-snapshot-index", snapshotIndex);
      this.graphicTarget.appendChild(div);
      this.snapshotRanges[index].element = div;
    });
  }

  constructLegendItems() {
    Object.keys(this.ribbons).forEach((name) => {
      if (this.legendItems[name]) {
        return;
      }

      this.legendItems[name] = {};
      const fragment = this.legendItemTemplate.content.cloneNode(true);
      const item = fragment.querySelector("div");
      const contentEl = fragment.querySelector(".js-legend-item__content");
      const voteLabelEl = fragment.querySelector(".js-legend-item__vote-label");
      const voteCountEl = fragment.querySelector(".js-legend-item__vote-count");
      const ribbonNameEl = fragment.querySelector(
        ".js-legend-item__ribbon-name"
      );
      ribbonNameEl.innerText = name;
      const positions = [];
      const points = this.paths[name].points;
      let position = window.innerHeight;
      while (position <= this.viewboxHeight) {
        const translation = -1 * position + window.innerHeight;
        const endPointIndex = points.findIndex((p) => position <= p.y);
        const endPoint = points[endPointIndex];
        const startPoint = points[endPointIndex - 1];
        let x;
        if (!startPoint) {
          x = window.innerWidth + this.strokeWidth;
        } else if (!endPoint) {
          x = startPoint.x;
          break;
        } else if (startPoint.x === endPoint.x) {
          x = startPoint.x;
        } else if (startPoint.x > endPoint.x) {
          const pointProgression =
            (position - startPoint.y) / (endPoint.y - startPoint.y);
          x = startPoint.x - (startPoint.x - endPoint.x) * pointProgression;
        } else if (startPoint.x < endPoint.x) {
          const pointProgression =
            (position - startPoint.y) / (endPoint.y - startPoint.y);
          x = startPoint.x + (endPoint.x - startPoint.x) * pointProgression;
        } else {
        }

        x = Math.round(x);

        positions.push({
          translation,
          position,
          x,
        });
        position += 10;
      }

      item.style.setProperty("transform", `translateX(100vw)`);

      this.legendItems[name] = {
        item,
        voteLabelEl,
        voteCountEl,
        contentEl,
        positions,
      };
      this.legendTarget.appendChild(fragment);
    });
  }

  animateThroughHistory() {
    this.timeModeValue = "historical";
    this.currentViewValue = this.historicalTimeframeLabel;

    const start = 0;
    const end = -1 * this.viewboxHeight + window.innerHeight;
    const duration = Math.abs(end) / this.speed;

    // Slide the SVG up until we reach the end
    const svgAnimation = this.graphicTarget.animate(
      [{ transform: `translateY(0px)` }, { transform: `translateY(${end}px)` }],
      { duration, fill: "forwards", easing: this.timingFunctionStr }
    );

    const { legendItems } = this;
    const updateVoteLabels = () => {
      if (svgAnimation.playState !== "running") {
        clearInterval(this.voteCountSyncInterval);
      }

      const { currentTime } = svgAnimation;
      const progress = currentTime / duration;
      const easedProgress = this.timingFunction(progress);
      const currentTranslation = start + (end - start) * easedProgress;
      const currentPosition = window.innerHeight - currentTranslation;
      const currentSnapshotRange = this.snapshotRanges.find((f) => {
        return f.top <= currentPosition && f.bottom >= currentPosition;
      });

      if (!currentSnapshotRange) {
        return;
      }

      const currentSnapshotIndex = currentSnapshotRange.snapshotIndex;
      const snapshot = this.history[currentSnapshotIndex];

      Object.values(snapshot.ribbons).forEach(
        ({ name, vote_count, voteCountChange, color_hex }) => {
          const legendItem = legendItems[name];
          if (legendItem.voteCountEl.textContent === `${vote_count}`) {
            return;
          }

          if (this.showHistoricalNotifications) {
            this.notify(name, voteCountChange || vote_count, color_hex);
          }

          legendItem.voteLabelEl.animate(
            [
              { transform: `scale(1)` },
              { transform: `scale(1.5)` },
              { transform: `scale(1)` },
            ],
            { duration: 200, fill: "forwards" }
          );
          legendItem.voteCountEl.textContent = vote_count;
        }
      );
    };

    const syncVoteCountsWithAnimation = () => {
      this.voteCountSyncInterval = setInterval(
        updateVoteLabels.bind(this),
        1000
      );
    };

    syncVoteCountsWithAnimation();
    svgAnimation.finished.then(() => {
      updateVoteLabels();
    });

    // Animate the legend items to follow each ribbon's path
    const legendAnimations = Object.values(this.legendItems).map(
      ({ item, positions }) => {
        const keyframes = positions.map((p) => ({
          transform: `translateX(calc(${p.x}px - 50%))`,
        }));
        return item.animate(keyframes, {
          duration,
          fill: "forwards",
          easing: this.timingFunctionStr,
        });
      }
    );

    // For each frame, update the vote counts
    this.animation = {
      svgAnimation,
      legendAnimations,
      start,
      end,
      duration,
      pause: () => {
        svgAnimation.pause();
        legendAnimations.forEach((a) => a.pause());
      },
      resume: () => {
        svgAnimation.play();
        legendAnimations.forEach((a) => a.play());
        syncVoteCountsWithAnimation();
      },
    };

    return svgAnimation.finished;
  }

  trackRealtimeData() {
    this.timeModeValue = "realtime";
    this.currentViewValue = "Latest responses";
    const poll = this.requestRecentVotes.bind(this);
    this.pollInterval = setInterval(poll, 1000);

    return Promise.resolve();
  }

  drawPositions() {
    let x = 0;
    while (x < this.viewboxHeight) {
      const label = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "text"
      );
      label.setAttribute("x", 0);
      label.setAttribute("y", x);
      label.setAttribute("fill", "#000");
      label.setAttribute("font-size", DATE_LABEL_FONT_SIZE);
      label.textContent = x;
      this.svgTarget.appendChild(label);
      x += 100;
    }
  }

  currentViewValueChanged(label) {
    if (label === this.currentViewLabelTarget.textContent) {
      return;
    }

    this.currentViewLabelTarget
      .animate([{ opacity: 1 }, { opacity: 0 }], {
        duration: 500,
        fill: "forwards",
      })
      .finished.then(() => {
        return wait(300);
      })
      .then(() => {
        this.currentViewLabelTarget.textContent = label;
        return this.currentViewLabelTarget.animate(
          [{ opacity: 0 }, { opacity: 1 }],
          {
            duration: 500,
            fill: "forwards",
          }
        ).finished;
      });
  }

  notify(name, vote_count, color_hex) {
    // TODO: either memoize this or use a vh unit so as to avoid layout thrashing
    const legendHeight = Object.values(
      this.legendItems
    )[0].item.getBoundingClientRect().height;

    const width = this.strokeWidth;
    const height = width * 2;
    const overlap = height * NOTIFICATION_OVERLAP;
    const fragment = this.notificationTemplateTarget.content.cloneNode(true);
    const notification = fragment.querySelector("div");
    const index = this.notifications.length;
    const slot = index % 5;

    notification.style.setProperty(
      "bottom",
      `${legendHeight + height * slot - overlap * slot}px`
    );
    const ribbonNameEl = notification.querySelector(
      ".js-notification__ribbon-name"
    );
    const voteCountEl = notification.querySelector(
      ".js-notification__vote-count"
    );
    const headEl = notification.querySelector(".js-notification__head");
    const tailEl = notification.querySelector(".js-notification__tail");
    headEl.style.setProperty("background-color", color_hex);
    tailEl.style.setProperty("background-color", color_hex);
    ribbonNameEl.textContent = name;
    voteCountEl.textContent = `+${vote_count}`;
    this.element.appendChild(fragment);
    this.notifications.push({
      slot,
      element: notification,
    });
    this.notifications.forEach((n, i) => {
      const nGroup = Math.floor(i / 5);
      n.element.style.setProperty("z-index", nGroup * 5 + (5 - n.slot));
    });

    return notification
      .animate(
        [{ transform: "translateX(100%)" }, { transform: "translateX(0)" }],
        {
          duration: 300,
          easing: "ease-in-out",
          fill: "forwards",
          delay: 100 * index,
        }
      )
      .finished.then(() => {
        return wait(3000);
      })
      .then(() => {
        this.notifications = this.notifications.filter(
          (n) => n.element !== notification
        );
        return notification.animate(
          [{ transform: "translateX(0)" }, { transform: "translateX(100%)" }],
          { duration: 300, easing: "ease-in-out", fill: "forwards" }
        ).finished;
      })
      .then(() => {
        notification.remove();
      });
  }

  teardownGraphic() {
    // Stop all animations
    this.animation.pause();

    // Stop all polling
    clearInterval(this.pollInterval);

    // Remove all legend items
    const legendPromises = Object.values(this.legendItems).map(
      ({ item }, i) => {
        return item.animate([{ opacity: 1 }, { opacity: 0 }], {
          duration: 500,
          delay: 50 * i,
          fill: "forwards",
        }).finished;
      }
    );

    // Fade out graphic
    const graphicPromise = this.graphicTarget
      .animate([{ opacity: 1 }, { opacity: 0 }], {
        duration: 1000,
        fill: "forwards",
      })
      .finished.then(() => {
        // Remove all paths
        Object.values(this.paths).forEach(({ path }) => {
          path.remove();
        });
      });

    // Remove all paths
    // const pathPromises = currentSnapshot.rankings.map((name, index) => {
    //   const {path, totalLength} = this.paths[name];
    //   path.style.setProperty("stroke-dasharray", totalLength);
    //   path.style.setProperty("stroke-dashoffset", 0);
    //   return path.animate([{ strokeDashoffset: 0 }, { strokeDashoffset: totalLength * -1 }], {
    //     duration: totalLength / 4,
    //     delay: 50 * index,
    //     fill: "forwards",
    //   }).finished.then(() => path.remove());
    // })
    // const pathPromises = Object.values(this.paths).map(({ path, totalLength }, i) => {
    //   path.style.setProperty("stroke-dasharray", totalLength);
    //   path.style.setProperty("stroke-dashoffset", 0);
    //   return path
    //     .animate([{ strokeDashoffset: 0 }, { strokeDashoffset: totalLength * -1 }], {
    //       // speed = distance / time
    //       // time = distance / speed
    //       duration: totalLength / 4,
    //       delay: 50 * i,
    //       fill: "forwards",
    //     })
    //     .finished.then(() => path.remove());
    // });

    // Remove all date labels
    const dateLabelPromises = this.dateLabels.map(({ elements }) => {
      return elements
        .map((e) => {
          return e
            .animate([{ opacity: 0 }], {
              duration: 500,
              fill: "forwards",
            })
            .finished.then(() => e.remove());
        })
        .flat();
    });

    // Remove all notifications
    this.notifications.forEach(({ element }) => {
      element
        .animate(
          [{ transform: "translateX(0)" }, { transform: "translateX(100%)" }],
          { duration: 300, easing: "ease-in-out", fill: "forwards" }
        )
        .finished.then(() => element.remove());
    });
    this.notifications = [];

    return Promise.all([
      graphicPromise,
      ...legendPromises,
      // ...pathPromises,
      ...dateLabelPromises,
    ]).then(() => {
      // Reset the translation value
      this.graphicTarget.getAnimations().forEach((a) => a.cancel());

      this.viewboxWidth = window.innerWidth;
      this.viewboxHeight = window.innerHeight;
      this.svgTarget.setAttribute(
        "viewBox",
        `0 0 ${this.viewboxWidth} ${this.viewboxHeight}`
      );
      this.svgTarget.setAttribute("width", this.viewboxWidth);
      this.svgTarget.setAttribute("height", this.viewboxHeight);
      this.svgTarget.style.setProperty("opacity", 1);
    });
  }

  showTopFive() {
    this.timeModeValue = "historical";
    this.currentViewValue = this.historicalTimeframeLabel;
    const finalSnapshot = this.history[this.history.length - 1];

    const drawDiamond = (name, color, vote_count, rank) => {
      const diamond = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "polygon"
      );
      const centerHeight = this.strokeWidth;
      const overallHeight = this.strokeWidth + centerHeight;
      const left = this.strokeWidth * rank;
      const horizontalMiddle = left + this.strokeWidth / 2;
      const right = left + this.strokeWidth;
      // const top = this.viewboxHeight / 2 - overallHeight / 2;
      const top = this.viewboxHeight;
      const middleTop = top + this.strokeWidth / 2;
      const verticalMiddle = middleTop + centerHeight / 2;
      const middleBottom = middleTop + centerHeight;
      const bottom = middleBottom + this.strokeWidth / 2;
      const textPosition = verticalMiddle - 25;
      const points = [
        { x: horizontalMiddle, y: top },
        { x: right, y: middleTop },
        { x: right, y: middleBottom },
        { x: horizontalMiddle, y: bottom },
        { x: left, y: middleBottom },
        { x: left, y: middleTop },
      ];
      diamond.setAttribute(
        "points",
        points.map(({ x, y }) => `${x},${y}`).join(" ")
      );
      diamond.setAttribute("fill", color);

      const voteCountLabel = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "text"
      );
      voteCountLabel.setAttribute("x", horizontalMiddle);
      voteCountLabel.setAttribute("y", textPosition);
      voteCountLabel.setAttribute("text-anchor", "middle");
      voteCountLabel.setAttribute("dominant-baseline", "middle");
      voteCountLabel.setAttribute("font-size", "72px");
      voteCountLabel.setAttribute(
        "font-family",
        DATE_LABEL_CONDENSED_FONT_FAMILY
      );
      voteCountLabel.setAttribute("font-weight", DATE_LABEL_FONT_WEIGHT);
      voteCountLabel.textContent = vote_count;

      const voteCountUnitLabel = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "text"
      );
      voteCountUnitLabel.setAttribute("x", horizontalMiddle);
      voteCountUnitLabel.setAttribute("y", textPosition + 50);
      voteCountUnitLabel.setAttribute("text-anchor", "middle");
      voteCountUnitLabel.setAttribute("dominant-baseline", "middle");
      voteCountUnitLabel.setAttribute("font-size", "28px");
      voteCountUnitLabel.setAttribute(
        "font-family",
        DATE_LABEL_EXTRA_CONDENSED_FONT_FAMILY
      );
      voteCountUnitLabel.setAttribute("font-weight", DATE_LABEL_FONT_WEIGHT);
      voteCountUnitLabel.textContent = "responses for";

      const nameLabel = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "text"
      );
      nameLabel.setAttribute("x", horizontalMiddle);
      nameLabel.setAttribute("y", textPosition + 80);
      nameLabel.setAttribute("text-anchor", "middle");
      nameLabel.setAttribute("dominant-baseline", "middle");
      nameLabel.setAttribute("font-size", "28px");
      nameLabel.setAttribute(
        "font-family",
        DATE_LABEL_EXTRA_CONDENSED_FONT_FAMILY
      );
      nameLabel.setAttribute("font-weight", DATE_LABEL_FONT_WEIGHT);
      nameLabel.textContent = name;

      const group = document.createElementNS("http://www.w3.org/2000/svg", "g");
      group.appendChild(diamond);
      group.appendChild(voteCountLabel);
      group.appendChild(voteCountUnitLabel);
      group.appendChild(nameLabel);

      this.svgTarget.appendChild(group);

      return group.animate(
        [
          {
            transform: `translateY(${
              -0.5 * window.innerHeight - overallHeight / 2
            }px)`,
          },
        ],
        {
          duration: 500,
          fill: "forwards",
          delay: 50 * rank,
          easing: "ease-in-out",
        }
      ).finished;
    };

    return Promise.all(
      finalSnapshot.rankings.map((name) => {
        const { color_hex, vote_count, rank } = finalSnapshot.ribbons[name];
        return drawDiamond(name, color_hex, vote_count, rank);
      })
    );
  }

  fadeOutEverything() {
    return this.mainContentTarget.animate(
      { opacity: 0 },
      { duration: FADE_DURATION, fill: "forwards", easing: "ease-in-out" }
    ).finished;
  }

  fadeInEverything() {
    return this.mainContentTarget.animate(
      { opacity: 1 },
      { duration: FADE_DURATION, fill: "forwards", easing: "ease-in-out" }
    ).finished;
  }

  showErrorMessage() {
    this.noDataMessageTarget.style.setProperty("opacity", 0);
    this.noDataMessageTarget.style.setProperty("display", "block");
    return this.noDataMessageTarget.animate(
      { opacity: 1 },
      { duration: 1000, fill: "forwards" }
    ).finished;
  }

  redirectToNextScreen() {
    window.location.href = this.data.get("next-screen-url");
  }
}
