import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "submitButton"];
  static values = {
    complete: Boolean,
  };

  connect() {
    window.comments = this;
    this.animation = null;
  }

  onChange() {
    this.completeValue = this.inputTarget.value.length > 0;
  }

  completeValueChanged(complete) {
    if (complete) {
      this.submitButtonTarget.style.setProperty("display", "block");
      this.submitButtonTarget.offsetHeight;
      this.submitButtonTarget.style.setProperty("transition", "opacity 200ms");
      this.submitButtonTarget.style.setProperty("opacity", "1");
    } else {
      this.submitButtonTarget.style.setProperty("transition", "opacity 200ms");
      this.submitButtonTarget.style.setProperty("opacity", "1");
      this.submitButtonTarget.offsetHeight;
      this.submitButtonTarget.style.setProperty("opacity", "0");
      setTimeout(() => {
        this.submitButtonTarget.style.setProperty("display", "block");
      }, 200);
    }
  }
}
