import { Controller } from "@hotwired/stimulus";

const TIME_BEFORE_NOTIFICATION = 60 * 1000; // 1 minute
const FINAL_COUNTDOWN = 10 * 1000; // 10 seconds

export default class extends Controller {
  static targets = ["notification", "countdown"]
  static values = {
    notificationVisible: Boolean,
    countdownLabel: Number,
  }

  connect() {
    this.start();

    document.addEventListener("mousemove", this.start.bind(this));
    document.addEventListener("keypress", this.start.bind(this));
  }

  start() {
    if (this.notificationVisibleValue) {
      return;
    }

    this.stop();
    this.timer = setTimeout(this.timeoutCallback.bind(this), TIME_BEFORE_NOTIFICATION);
  }

  stop() {
    clearTimeout(this.timer);
    clearInterval(this.finalCountdownInterval);
    this.notificationVisibleValue = false;
  }

  timeoutCallback() {
    this.notificationVisibleValue = true;
    
    this.timer = setTimeout(this.finalCountdownCallback.bind(this), FINAL_COUNTDOWN);
    this.countdownLabelValue = FINAL_COUNTDOWN / 1000;
  
    this.finalCountdownInterval = setInterval(() => {
      this.countdownLabelValue -= 1;
      if (this.countdownLabelValue === 0) {
        clearInterval(this.finalCountdownInterval);
      }
    }, 1000);

  }

  notificationVisibleValueChanged(notificationVisible) {
    if (notificationVisible) {
      this.notificationTarget.style.setProperty('display', 'flex');
      this.notificationTarget.style.setProperty('opacity', '0');
      this.notificationTarget.animate(
        [{ opacity: 0 }, { opacity: 1 }],
        { duration: 200, fill: "forwards" }
      )
    } else {
      this.notificationTarget.animate(
        [{ opacity: 1 }, { opacity: 0 }],
        { duration: 200, fill: "forwards" }
      ).finished.then(() => {
        this.notificationTarget.style.setProperty('display', 'none');
      })
    }
  }

  finalCountdownCallback() {
    window.location.reload();
  }

  countdownLabelValueChanged(countdownLabel) {
    if (!this.hasCountdownTarget) {
      return;
    }

    this.countdownTarget.innerText = countdownLabel;
  }

  dismissNotification() {
    this.notificationVisibleValue = false;
    this.start();
  }
}