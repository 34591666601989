import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "optionList",
    "optionListItem",
    "selectionList",
    "selectionListItem",
    "selectionTemplate",
    "customSelectionTemplate",
    "selectionLabel",
    "nextButtonContainer",
    "nextButton",
    "formOption",
  ];
  static values = {
    count: Number,
    complete: Boolean,
  };

  connect() {
    window.picker = this;
  }

  deselect(e) {
    const { selection } = e.params;
    const selectionStr = selection.toString();
    const formOption = this.formOptionTargets.find(
      (option) => option.value === selectionStr
    );
    formOption.selected = false;
    const optionListItem = this.optionListItemTargets.find(
      (item) => item.dataset.pickerSelectionParam === selectionStr
    );
    optionListItem.disabled = false;
    e.target.closest("li").remove();
    this.countValue--;
    this.resetToLastColor();
  }

  resetToLastColor() {
    const lastSelection =
      this.selectionListItemTargets[this.selectionListItemTargets.length - 1];
    if (lastSelection) {
      const { color } = lastSelection.dataset;
      this.dispatch("color-change", { detail: { color } });
    } else {
      this.dispatch("color-change", { detail: { color: "#FFFFFF" } });
    }
  }

  select(e) {
    const { selection, color } = e.params;
    const selectionStr = selection.toString();
    const formOption = this.formOptionTargets.find(
      (option) => option.value === selectionStr
    );
    formOption.selected = true;
    e.target.disabled = true;
    const selectionDisplay =
      this.selectionTemplateTarget.content.cloneNode(true);
    const selectionElement = selectionDisplay.querySelector("li");
    selectionElement.dataset.color = color;
    selectionElement.dataset.pickerTarget = "selectionListItem";
    const label = selectionDisplay.querySelector(
      "[data-picker-target='selectionLabel']"
    );
    const removeButton = selectionDisplay.querySelector("button");
    label.textContent = formOption.textContent;
    removeButton.dataset.action = "picker#deselect";
    removeButton.dataset.pickerSelectionParam = selectionStr;
    this.selectionListTarget.appendChild(selectionDisplay);
    this.countValue++;
    this.dispatch("color-change", { detail: { color } });
  }

  addCustomSelection() {
    const prompt = this.customSelectionTemplateTarget.content.cloneNode(true);
    const promptElement = prompt.querySelector("li");
    // TODO: pick random color
    promptElement.dataset.color = "#cccccc";
    promptElement.dataset.pickerTarget = "selectionListItem";
    const removeButton = prompt.querySelector("button");
    removeButton.dataset.action = "picker#removeCustomSelection";
    const input = prompt.querySelector("input");
    this.selectionListTarget.appendChild(prompt);
    input.focus();
    this.countValue++;
    this.dispatch("color-change", { detail: { color: "#cccccc" } });
  }

  removeCustomSelection(e) {
    e.target.closest("li").remove();
    this.countValue--;
    this.optionListItemTarget.focus();
    this.resetToLastColor();
  }

  countValueChanged(count) {
    this.completeValue = count === 3;
  }

  completeValueChanged(complete) {
    const elementToFadeOut = complete
      ? this.optionListTarget
      : this.nextButtonTarget;
    const elementToFadeIn = complete
      ? this.nextButtonTarget
      : this.optionListTarget;
    const elementToFocus = complete
      ? this.nextButtonTarget
      : this.optionListTarget;
    const fadeOut = elementToFadeOut.animate([{ opacity: 1 }, { opacity: 0 }], {
      duration: 300,
      fill: "forwards",
    });
    fadeOut.finished
      .then(() => {
        elementToFadeOut.style.setProperty("display", "none");
        elementToFadeIn.style.setProperty("display", "block");
        elementToFadeIn.style.setProperty("opacity", "0");
        const fadeIn = elementToFadeIn.animate(
          [{ opacity: 0 }, { opacity: 1 }],
          {
            duration: 300,
            fill: "forwards",
          }
        );
        return fadeIn.finished;
      })
      .then(() => {
        console.log("focus the next button", elementToFocus);
        elementToFocus.focus();
      });
  }

  returnFocusToOptions() {
    this.optionListItemTarget.focus();
  }
}
